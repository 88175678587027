.ship-form-wrapper{
  min-height: 65vh;
  padding: 30px;
  background: white;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.ship-form-wrapper.ship-form-wrapper-search{
  background: #f5f5f5;
}

.sp-m-123{
  background:"";
  color:"";
}
.sp-main{
  padding:1em;
}
.sp-form{
  max-width:660px;
  margin:auto;
}

.field-group,
.groups-box{
  display:grid;
  gap:0.5em;
}
.field-ships{
  display:flex;
  align-items:center;
  gap:1em;
  flex-wrap:wrap;
  justify-content:flex-start;
}
.field-chip .ui-selectmenu-button.ui-button{
  width:150px;
  background:#fbfbfb;
  border-radius:0;
}
.sp-field.map,
.sp-field.mv{
  position:relative;
}
.sp-field.map label,
.sp-field.mv label{
  position:absolute;
  top:0px;
  left:10px;
  font-size:13px;
  color:gray;
}
.sp-field.select {
  position:relative;
}
.sp-field.select label{
  position:absolute;
  top:0px;
  left:10px;
  font-size:13px;
  color:gray;
  z-index:1;
}
.sp-field.select  .ui-selectmenu-button.ui-button{
  background:#fff;
  width: 100%;
  padding: 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  color:black;
}
.sp-field.map .input-icon{
  position:absolute;
  top:10px;
  right:10px;
}
.sp-field.mv .input-icon{
  position:absolute;
  top:20px;
  left:10px;
}
.sp-field.mv .input_field{
  padding: 20px 15px 15px 25px;
}
.sp-field.map .input-icon .icon,
.sp-field.mv .input-icon .icon{
  width:16px;
  height:16px;
  fill:lightgray;
}

@media(max-width:768px){
  .field-chip .ui-selectmenu-button.ui-button{
    width:130px;
    font-size:14px;
  }
}


/* Custom */